<template>
     <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Waiting List</h5>
                     <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close()"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card mt-2">
                                <div class="card-header">
                                    {{listEventWaiting[0].jadwal.jamMulai}} - {{listEventWaiting[0].jadwal.jamSelesai}}
                                    <!-- {{jadwal.queueNoFormatted}} -->
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-4 p-2 pt-2 pb-5 m-3 bg-reserved rounded pointer"
                                            v-for="list in listEventWaiting" :key="list.id"
                                            @click="makeEvent(list)"
                                        >
                                            {{list.reservasi.namaPasien}} - {{list.reservasi.kodeBooking}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <panel-info :open="openModal"></panel-info>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SlotJadwalType from "./../../../constants/slot-jadwal-type-enum";
    import { mapState, mapMutations, mapActions } from "vuex";
    import PanelInfo from './../DayCalendar/_components/PanelInfo.vue';
    export default {
        components: {
            PanelInfo,
        },
        props: {
            modalName: { type: String, default:"wlModal"},
        },
        computed: {
            ...mapState('DayCalendarStore', {
                listEventWaiting: state => state.listEventWaiting
            }),
            getJam(){
                let jadwal = []

            }
        },
        methods: {
             ...mapMutations({
                setShowModalWaiting: "DayCalendarStore/SET_SHOW_MODAL_WAITING",
                setSelectedEvent: "DayCalendarStore/SET_SELECTED_EVENT",
                setShowModalReservasi: "DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI",
                setFromEvent: "DayCalendarStore/SET_FROM_EVENT",
                setPanelInfo: 'DayCalendarStore/SET_PANEL_INFO'
            }),
            ...mapActions({
                getInfo: 'DayCalendarStore/GET_INFO_PANEL'
            }),
            close() {
                $("#" + this.modalName).modal("hide");
                this.setShowModalWaiting(false);
            },
            makeEvent(reservasi){
                var objectEvent = {
                    jadwal: reservasi.jadwal,
                    type: SlotJadwalType.WAITING,
                    reservasi: reservasi,
                    start: moment(`${reservasi.reservasi.tanggalKunjungan} ${reservasi.jadwal.jamMulai}`, 'YYYY-MM-DD HH:mm').format( 'YYYY-MM-DD HH:mm'),
                    end: moment(`${reservasi.reservasi.tanggalKunjungan} ${reservasi.jadwal.jamSelesai}`, 'YYYY-MM-DD HH:mm').format( 'YYYY-MM-DD HH:mm')
                };
                // console.log(objectEvent);
                this.getInfo(reservasi.reservasi.generatedId)
                this.setSelectedEvent(objectEvent);
            },
            openModal(){
                this.close();
                this.setFromEvent(true);
                this.setShowModalReservasi(true);
            }
        },
        mounted () {
            $("#" + this.modalName).modal();
            this.setSelectedEvent(null);
            this.setPanelInfo({})
            // console.log(this.listEventWaiting);
            
        },
    }
</script>

<style lang="scss" scoped>

</style>
